import Swiper from 'swiper';
import { Autoplay, Pagination, Scrollbar, Navigation, EffectFade, Virtual } from 'swiper/modules';
Swiper.use([Autoplay, Pagination, Scrollbar, Navigation, EffectFade, Virtual]);

import gsap from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger);


/*import Lenis from '@studio-freight/lenis';

//loading
import { headerBtn } from './module/common/headerBtn';*/

//anchor link
import { anchorLink } from './module/common/anchorLink';

//odometer
import { odoMator } from './module/top/odometer.min.js';

const setScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	// カスタムプロパティの値を更新する
	document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
};

setScrollbarWidth();
//
window.addEventListener("pageshow", function () {
	/*const lenis = new Lenis({
		lerp: 0.3, // 慣性の強さ
		duration: 1.7, // スクロールアニメーションの時間
		easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
		smooth: true,
		smoothTouch: false,
	});

	function raf(time) {
		lenis.raf(time);
		ScrollTrigger.update();
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);*/

	const btn = document.querySelector('.gnav__btn');
	const links = document.querySelectorAll('.gnav a');
	var menu_target = document.querySelector('.gnav');
	const sbtn = document.querySelector('#service-menu__btn');
	var smenu_target = document.querySelector('#service-menu');


	const cbtn = document.querySelector('.service-menu__close');


	let state = false;
	let sstate = false;
	let scrollbarWidth = window.innerWidth - document.body.clientWidth;
	const vh = window.innerHeight;
	const windowHeight = document.documentElement.clientHeight;
	const header = document.querySelector('.header');
	const headerHeight = header.offsetHeight;

	btn.addEventListener("click", function () {
		if (state == false) {
			this.classList.add('active');
			//html.classList.add('fixed');
			menu_target.classList.add('active');
			document.querySelector('body').classList.add('fixed');
			btn.style.marginRight = scrollbarWidth + 'px';
			document.querySelector('body').style.marginRight = scrollbarWidth + 'px';
			state = true;
			//lenis.stop();
		} else {
			this.classList.remove('active');
			//html.classList.remove('fixed');
			menu_target.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			btn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			state = false;
			//lenis.start();
		}
	});

	links.forEach(link => {
		link.addEventListener("click", function () {
			this.classList.remove('active');
			//html.classList.remove('fixed');
			menu_target.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			btn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			state = false;
			//lenis.start();
		});
	});
	anchorLink();
	odoMator();

	const top_slider1 = new Swiper('.client__slide1', {
		spaceBetween: 32,
		slidesPerView: 2,
		loop: true, // ループ有効
		speed: 9000, // ループの時間
		allowTouchMove: false, // スワイプ無効
		autoplay: {
			delay: 0, // 途切れなくループ
		},
		breakpoints: {
			768: {
			  slidesPerView: 4,
			  spaceBetween: 44,
			},
			1280: {
			  slidesPerView: 6,
			  spaceBetween: 66,
			}
		    }
	});

	const top_slider2 = new Swiper('.client__slide2', {
		spaceBetween: 32,
		slidesPerView: 2,
		loop: true, // ループ有効
		speed: 9000, // ループの時間
		allowTouchMove: false, // スワイプ無効
		autoplay: {
			delay: 0, // 途切れなくループ
			reverseDirection: true
		},
		breakpoints: {
			768: {
			  slidesPerView: 4,
			  spaceBetween: 44,
			},
			1280: {
			  slidesPerView: 6,
			  spaceBetween: 66,
			}
		    }
	});

	const top_design_slider = new Swiper('.top__design__slide', {
		centeredSlides: true,
		loop: true,
		speed: 500,
		slidesPerView: 2.2,
		autoplay: {
			delay: 3000,
		},
		breakpoints: {
			868: {
			  slidesPerView: 4,
			}
		    }
	});

	const toggleBtn = document.querySelectorAll('.js-toggle-btn');

	const toggleBtnArr = Array.prototype.slice.call(toggleBtn);
	toggleBtn.forEach(btn => {
		btn.addEventListener('click', e => {
			btn.parentNode.classList.toggle("active");
		});
	});

	/*const slider5 = new Swiper('.member__slider--wrapper2', {
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: ".member-button-next",
			prevEl: ".member-button-prev",
		},
		breakpoints: {
			// スライドの表示枚数：500px以上の場合
			599: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			868: {
				slidesPerView: 1,
				spaceBetween: 0,
			}
		}
	});

	function setTextAnim() {
		const showText = document.querySelectorAll(".js-show-text");
		showText.forEach(showText => {
			const letters = showText.textContent.split("");
			showText.textContent = '';

			letters.forEach(letter => {
				const span = document.createElement("span");
				span.classList.add('w');
				span.style.opacity = 0;
				span.textContent = letter;
				showText.appendChild(span);
			});
			showText.style.opacity = 1;

			const letterA = showText.querySelectorAll('span');
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: showText,
					start: 'top bottom-=20%',
				}
			});
			tl.fromTo(letterA, {
				autoAlpha: 0,
				yPercent: 100,
			}, {
				duration: 0.8,
				autoAlpha: 1,
				yPercent: 0,
				ease: "expo.out",
				stagger: {
					each: .02,
				}
			});
		});
	}
	requestAnimationFrame(setTextAnim);


	function setPicAnim() {
		const showText = document.querySelectorAll(".js-show-text");
		showText.forEach(showText => {
			const letters = showText.textContent.split("");
			showText.textContent = '';

			letters.forEach(letter => {
				const span = document.createElement("span");
				span.style.opacity = 0;
				span.textContent = letter;
				showText.appendChild(span);
			});

			const letterA = showText.querySelectorAll('span');
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: showText,
					start: 'top bottom-=20%',
				}
			});
			tl.fromTo(letterA, {
				autoAlpha: 0,
				yPercent: 100,
			}, {
				duration: 0.7,
				autoAlpha: 1,
				yPercent: 0,
				ease: "expo.out",
				stagger: {
					each: .02,
				}
			});
		});
	}
	requestAnimationFrame(setTextAnim);



	/*const siteBtns = document.querySelectorAll('.site__btn a');
	siteBtns.forEach(siteBtn => {
		siteBtn.addEventListener('mouseover', e => {
			const siteBtnText = siteBtn.querySelector('.text');

			let tl = gsap.timeline();
			tl.fromTo(siteBtn,
				{
					'--moveArrow': '0%',
					autoAlpha:1,
				},
				{
					autoAlpha:0,
					'--moveArrow': '100%'
				}
			);
			tl.fromTo(siteBtn,
				{
					autoAlpha:0,
					'--moveArrow': '-100%'
				},
				{
					autoAlpha:1,
					'--moveArrow': '0%'
				}
			);
		});
	});*/
});

window.addEventListener("resize", function () {
	setScrollbarWidth();
});
